<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 250 250" xml:space="preserve" :width="size + 'px'" :fill="color">
    <g xmlns="http://www.w3.org/2000/svg" id="Layer_8">
        <g>
            <g>
                <circle class="st0" cx="125" cy="125.5" r="124.5"/>
            </g>
            <g>
                <path class="st1" d="M174.6,72h-36.4v34.5L125,97.6l-13.2,8.9V72H75.4c-2.2,0-3.9,1.8-3.9,3.9V170c0,5,4,9,9,9h89c5,0,9-4,9-9     V75.9C178.5,73.7,176.8,72,174.6,72z M166.4,165.7h-38.7c-0.7,0-1.4-0.6-1.4-1.4v-7.7c0-0.7,0.6-1.4,1.4-1.4h38.7     c0.7,0,1.4,0.6,1.4,1.4v7.7C167.8,165.1,167.2,165.7,166.4,165.7z M166.4,148.6h-59.5c-0.7,0-1.4-0.6-1.4-1.4v-7.7     c0-0.7,0.6-1.4,1.4-1.4h59.5c0.7,0,1.4,0.6,1.4,1.4v7.7C167.8,148,167.2,148.6,166.4,148.6z"/>
                <path class="st2" d="M167.8,156.6v7.7c0,0.7-0.6,1.4-1.4,1.4h-38.7c-0.7,0-1.4-0.6-1.4-1.4v-7.7c0-0.7,0.6-1.4,1.4-1.4h38.7     C167.2,155.3,167.8,155.9,167.8,156.6z"/>
            </g>
        </g>
    </g>
</svg>
</template>
<script>
export default {
    name: 'Orders',
    props:{
    size: {
        type:Number,
        default: 20,
        },
    color:{
        type: String,
        default: ''
    }
    }
}
</script>
<style scoped>
	.st0{fill:#E1F3F5;}
	.st1{fill:#00B6C5;}
	.st2{fill:#B9E8EF;}
</style>